import { render, staticRenderFns } from "./JusticiaLibre.vue?vue&type=template&id=6409ad31&scoped=true"
import script from "./JusticiaLibre.vue?vue&type=script&lang=js"
export * from "./JusticiaLibre.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6409ad31",
  null
  
)

export default component.exports