var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.envivo)?_c('section',{staticClass:"videos bg-gray"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-center pb-4 mt-4"},[_c('h1',{staticClass:"titulo-seccion",style:({
            '--border-color': _vm.config.disenio.datos.color_primario,
            '--color-secondary': _vm.config.disenio.datos.color_secundario,
          }),attrs:{"id":"envivo","name":"envivo"}},[_vm._v(" Transmisión en Vivo ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-center mt-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12",attrs:{"id":"box-transmision"}},[(_vm.item.logo)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.item.logo),expression:"item.logo"}],attrs:{"alt":_vm.item.titulo}}):_vm._e(),_c('div',{staticClass:"embed-responsive embed-responsive-16by9 ratio ratio-16x9"},[_c('iframe',{staticClass:"embed-responsive-item",attrs:{"src":_vm.item.url,"allowfullscreen":""}})]),_c('div',{staticClass:"text-center mt-3"},[_c('router-link',{staticClass:"btn btn-md btn-fiscalia",style:({
                  '--background-color-btn':
                    _vm.config.disenio.datos.color_primario,
                }),attrs:{"to":"/transmisiones"}},[_c('i',{staticClass:"icon"},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M11.2969 3.5625H12.7031C12.8281 3.5625 12.8906 3.625 12.8906 3.75V20.25C12.8906 20.375 12.8281 20.4375 12.7031 20.4375H11.2969C11.1719 20.4375 11.1094 20.375 11.1094 20.25V3.75C11.1094 3.625 11.1719 3.5625 11.2969 3.5625Z"}}),_c('path',{attrs:{"d":"M4.125 11.1094H19.875C20 11.1094 20.0625 11.1719 20.0625 11.2969V12.7031C20.0625 12.8281 20 12.8906 19.875 12.8906H4.125C4 12.8906 3.9375 12.8281 3.9375 12.7031V11.2969C3.9375 11.1719 4 11.1094 4.125 11.1094Z"}})])]),_vm._v(" Transmisiones ")])],1)])])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }