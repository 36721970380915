<template>
  <section class="videos bg-gray">
    <div class="container">
      <div class="row">
        <div
          class="col-md-6 text-center mt-5"
          data-aos="fade-left"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
        >
          <!-- area postcast -->
          <div class="pb-4">
            <img
              v-if="config.fiscaliatv.datos.imagen_titulo_podcasts"
              v-lazy="config.fiscaliatv.datos.imagen_titulo_podcasts"
              alt="PodCasts"
              class="w-40"
            />
            <h1
              v-else
              class="titulo-seccion"
              :style="{
                '--border-color': config.disenio.datos.color_primario,
                '--color-secondary': config.disenio.datos.color_secundario,
              }"
            >
              PodCasts
            </h1>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div
                class="embed-responsive embed-responsive-16by9 ratio ratio-16x9 bg-tertiary"
                :style="{
                  '--background-color-tertiary':
                    config.disenio.datos.color_terciario,
                }"
              >
                <iframe
                  class="embed-responsive-item"
                  :src="item.url"
                  allowfullscreen
                ></iframe>
              </div>

              <div class="text-center mt-5">
                <router-link
                  to="/podcasts"
                  class="btn btn-md btn-fiscalia"
                  :style="{
                    '--background-color-btn':
                      config.disenio.datos.color_primario,
                  }"
                >
                  <i class="icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.2969 3.5625H12.7031C12.8281 3.5625 12.8906 3.625 12.8906 3.75V20.25C12.8906 20.375 12.8281 20.4375 12.7031 20.4375H11.2969C11.1719 20.4375 11.1094 20.375 11.1094 20.25V3.75C11.1094 3.625 11.1719 3.5625 11.2969 3.5625Z"
                      />
                      <path
                        d="M4.125 11.1094H19.875C20 11.1094 20.0625 11.1719 20.0625 11.2969V12.7031C20.0625 12.8281 20 12.8906 19.875 12.8906H4.125C4 12.8906 3.9375 12.8281 3.9375 12.7031V11.2969C3.9375 11.1719 4 11.1094 4.125 11.1094Z"
                      />
                    </svg>
                  </i>
                  Ver Podcasts
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-md-6 text-center mt-5"
          data-aos="fade-right"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
        >
          <div class="pb-4 text-center">
            <img
              v-if="config.fiscaliatv.datos.imagen_titulo_fiscaliatv"
              v-lazy="config.fiscaliatv.datos.imagen_titulo_fiscaliatv"
              alt="Fiscalía TV"
              class="w-40 "
            />
            <h1
              v-else
              class="titulo-seccion"
              :style="{
                '--border-color': config.disenio.datos.color_primario,
                '--color-secondary': config.disenio.datos.color_secundario,
              }"
            >
              Fiscalía TV
            </h1>
          </div>
          <div
            v-if="config.videoftv.videoId"
            class="embed-responsive embed-responsive-16by9 ratio ratio-16x9"
          >
            <iframe
              class="embed-responsive-item"
              :src="'https://www.youtube.com/embed/' + config.videoftv.videoId"
              allowfullscreen
            ></iframe>
          </div>
          <div v-else>
            <div
              v-if="config.fiscaliatv.datos.fondo_fiscaliatv"
              class="embed-responsive embed-responsive-16by9 ratio ratio-16x9"
              id="fiscalia-tv"
              :style="
                'background-image: url(' +
                config.fiscaliatv.datos.fondo_fiscaliatv +
                ');'
              "
            >
              <router-link to="/videos/playlists/fiscaliatv">
                <img
                  v-if="config.fiscaliatv.datos.logo_fiscaliatv"
                  v-lazy="config.fiscaliatv.datos.logo_fiscaliatv"
                  alt="Logo"
                />
                <svg
                  v-else
                  width="143"
                  height="115"
                  viewBox="0 0 143 115"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M102.06 6.89999H56.94V18.22H100.09C100.826 18.2226 101.532 18.5164 102.053 19.0371C102.574 19.5579 102.867 20.2635 102.87 21V64.7H114.19V19C114.182 15.7881 112.901 12.7105 110.627 10.4422C108.353 8.17384 105.272 6.89998 102.06 6.89999V6.89999ZM41.11 71C40.3735 70.9974 39.6679 70.7036 39.1472 70.1828C38.6264 69.6621 38.3326 68.9565 38.33 68.22V38.48H27V70.17C27 73.3871 28.278 76.4724 30.5528 78.7472C32.8276 81.022 35.9129 82.3 39.13 82.3H66.6V71H41.11Z"
                    fill="#3D7AAF"
                  />
                  <path
                    d="M63.3301 29.61L84.7601 42.74C85.0981 42.9556 85.3755 43.254 85.5656 43.607C85.7558 43.96 85.8524 44.3558 85.8464 44.7567C85.8404 45.1577 85.7319 45.5504 85.5312 45.8975C85.3306 46.2446 85.0445 46.5347 84.7001 46.74L63.3501 59.05C62.996 59.2568 62.5939 59.3669 62.1839 59.3693C61.7739 59.3718 61.3705 59.2664 61.014 59.0639C60.6575 58.8613 60.3605 58.5687 60.1527 58.2153C59.9449 57.8618 59.8337 57.46 59.8301 57.05L59.7501 31.65C59.7433 31.2289 59.8498 30.8137 60.0585 30.4478C60.2672 30.082 60.5704 29.779 60.9363 29.5704C61.3022 29.3619 61.7175 29.2556 62.1386 29.2625C62.5597 29.2695 62.9712 29.3895 63.3301 29.61V29.61Z"
                    fill="#EE7203"
                  />
                  <path
                    d="M8.94 91.56V88.44H0V112.37H4V100.84H8.75V97.72H4V91.56H8.94Z"
                    fill="#3D7AAF"
                  />
                  <path
                    d="M10.3999 88.44V112.37H14.3999V88.44H10.3999Z"
                    fill="#3D7AAF"
                  />
                  <path
                    d="M27.0001 97.05V92C27.0001 88.87 24.2501 88 21.7701 88C19.2901 88 16.5901 88.9 16.5901 92V95.43C16.5901 99.33 23.0101 102.62 23.0101 105.99V108.5C23.0101 109.72 22.6001 109.99 21.8101 109.99C21.0201 109.99 20.5701 109.72 20.5701 108.5V103.3H16.5701V108.81C16.5701 111.94 19.3201 112.81 21.7501 112.81C24.1801 112.81 26.9801 111.91 26.9801 108.81V104.57C26.9801 102.16 20.5501 96.98 20.5501 95.26V92.26C20.5501 91.04 20.9601 90.77 21.7901 90.77C22.6201 90.77 22.9901 91.04 22.9901 92.26V97L27.0001 97.05Z"
                    fill="#3D7AAF"
                  />
                  <path
                    d="M39.5001 97.18V92C39.5001 88.87 36.7501 88 34.2701 88C31.7901 88 29.1001 88.9 29.1001 92V108.81C29.1001 111.94 31.8401 112.81 34.2701 112.81C36.7001 112.81 39.5001 111.91 39.5001 108.81V103.33H35.5001V108.5C35.5001 109.72 35.0801 109.99 34.2901 109.99C33.5001 109.99 33.0501 109.72 33.0501 108.5V92.31C33.0501 91.09 33.4701 90.82 34.2901 90.82C35.1101 90.82 35.5001 91.09 35.5001 92.31V97.18H39.5001Z"
                    fill="#3D7AAF"
                  />
                  <path
                    d="M49.78 88.44H43.78L40.78 112.37H44.39L45.25 105.89H48.06L48.82 112.37H52.82L49.78 88.44ZM45.61 102.76L46.61 92.76H46.87L47.7 102.76H45.61Z"
                    fill="#3D7AAF"
                  />
                  <path
                    d="M58.2 109.25V88.44H54.2V112.37H63.2V109.25H58.2Z"
                    fill="#3D7AAF"
                  />
                  <path
                    d="M68.9999 83L67.7999 84.48L65.4699 87.37H67.4699L68.3599 86.63L70.9999 84.48L72.6799 83H68.9999ZM64.6499 88.41V112.34H68.6499V88.44L64.6499 88.41Z"
                    fill="#3D7AAF"
                  />
                  <path
                    d="M79 88.44H73L70 112.37H73.61L74.47 105.89H77.28L78.03 112.37H82.03L79 88.44ZM74.84 102.76L75.84 92.76H76.1L76.93 102.76H74.84Z"
                    fill="#3D7AAF"
                  />
                  <path
                    d="M50.7201 16V25.4C50.4525 25.3552 50.1814 25.3351 49.9101 25.34C48.1418 25.3426 46.4462 26.0442 45.193 27.2917C43.9398 28.5393 43.2306 30.2317 43.2201 32H33.8401C33.8586 27.7501 35.5599 23.6806 38.5716 20.682C41.5833 17.6834 45.6602 16 49.9101 16C50.1801 16 50.4501 16 50.7201 16Z"
                    fill="#EE7203"
                  />
                  <path
                    d="M50.7199 0V9.39H49.9099C43.9112 9.39789 38.1596 11.781 33.9132 16.018C29.6668 20.2551 27.271 26.0013 27.2499 32H17.8799C17.8799 23.5131 21.2513 15.3737 27.2525 9.37258C33.2536 3.37142 41.393 0 49.8799 0L50.7199 0Z"
                    fill="#EE7203"
                  />
                  <path
                    d="M114.16 71C113.957 74.0713 112.594 76.9505 110.347 79.0543C108.101 81.1581 105.138 82.3291 102.06 82.33H96.2501V114.72H84.9201V82.3H72.8201V71H114.16Z"
                    fill="#EE7203"
                  />
                  <path
                    d="M142.69 71.82L127.47 114.69H116.13L105.6 87C109.309 86.1124 112.554 83.8731 114.7 80.72L121.81 98.72L131.36 71.79L142.69 71.82Z"
                    fill="#EE7203"
                  />
                </svg>
              </router-link>
            </div>
            <div
              v-else
              class="embed-responsive embed-responsive-16by9 ratio ratio-16x9"
              id="fiscalia-tv"
            >
              <router-link to="/videos/playlists/fiscaliatv">
                <img
                  v-if="config.fiscaliatv.datos.logo_fiscaliatv"
                  v-lazy="config.fiscaliatv.datos.logo_fiscaliatv"
                  alt="Logo"
                />
                <svg
                  v-else
                  width="143"
                  height="115"
                  viewBox="0 0 143 115"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M102.06 6.89999H56.94V18.22H100.09C100.826 18.2226 101.532 18.5164 102.053 19.0371C102.574 19.5579 102.867 20.2635 102.87 21V64.7H114.19V19C114.182 15.7881 112.901 12.7105 110.627 10.4422C108.353 8.17384 105.272 6.89998 102.06 6.89999V6.89999ZM41.11 71C40.3735 70.9974 39.6679 70.7036 39.1472 70.1828C38.6264 69.6621 38.3326 68.9565 38.33 68.22V38.48H27V70.17C27 73.3871 28.278 76.4724 30.5528 78.7472C32.8276 81.022 35.9129 82.3 39.13 82.3H66.6V71H41.11Z"
                    fill="#3D7AAF"
                  />
                  <path
                    d="M63.3301 29.61L84.7601 42.74C85.0981 42.9556 85.3755 43.254 85.5656 43.607C85.7558 43.96 85.8524 44.3558 85.8464 44.7567C85.8404 45.1577 85.7319 45.5504 85.5312 45.8975C85.3306 46.2446 85.0445 46.5347 84.7001 46.74L63.3501 59.05C62.996 59.2568 62.5939 59.3669 62.1839 59.3693C61.7739 59.3718 61.3705 59.2664 61.014 59.0639C60.6575 58.8613 60.3605 58.5687 60.1527 58.2153C59.9449 57.8618 59.8337 57.46 59.8301 57.05L59.7501 31.65C59.7433 31.2289 59.8498 30.8137 60.0585 30.4478C60.2672 30.082 60.5704 29.779 60.9363 29.5704C61.3022 29.3619 61.7175 29.2556 62.1386 29.2625C62.5597 29.2695 62.9712 29.3895 63.3301 29.61V29.61Z"
                    fill="#EE7203"
                  />
                  <path
                    d="M8.94 91.56V88.44H0V112.37H4V100.84H8.75V97.72H4V91.56H8.94Z"
                    fill="#3D7AAF"
                  />
                  <path
                    d="M10.3999 88.44V112.37H14.3999V88.44H10.3999Z"
                    fill="#3D7AAF"
                  />
                  <path
                    d="M27.0001 97.05V92C27.0001 88.87 24.2501 88 21.7701 88C19.2901 88 16.5901 88.9 16.5901 92V95.43C16.5901 99.33 23.0101 102.62 23.0101 105.99V108.5C23.0101 109.72 22.6001 109.99 21.8101 109.99C21.0201 109.99 20.5701 109.72 20.5701 108.5V103.3H16.5701V108.81C16.5701 111.94 19.3201 112.81 21.7501 112.81C24.1801 112.81 26.9801 111.91 26.9801 108.81V104.57C26.9801 102.16 20.5501 96.98 20.5501 95.26V92.26C20.5501 91.04 20.9601 90.77 21.7901 90.77C22.6201 90.77 22.9901 91.04 22.9901 92.26V97L27.0001 97.05Z"
                    fill="#3D7AAF"
                  />
                  <path
                    d="M39.5001 97.18V92C39.5001 88.87 36.7501 88 34.2701 88C31.7901 88 29.1001 88.9 29.1001 92V108.81C29.1001 111.94 31.8401 112.81 34.2701 112.81C36.7001 112.81 39.5001 111.91 39.5001 108.81V103.33H35.5001V108.5C35.5001 109.72 35.0801 109.99 34.2901 109.99C33.5001 109.99 33.0501 109.72 33.0501 108.5V92.31C33.0501 91.09 33.4701 90.82 34.2901 90.82C35.1101 90.82 35.5001 91.09 35.5001 92.31V97.18H39.5001Z"
                    fill="#3D7AAF"
                  />
                  <path
                    d="M49.78 88.44H43.78L40.78 112.37H44.39L45.25 105.89H48.06L48.82 112.37H52.82L49.78 88.44ZM45.61 102.76L46.61 92.76H46.87L47.7 102.76H45.61Z"
                    fill="#3D7AAF"
                  />
                  <path
                    d="M58.2 109.25V88.44H54.2V112.37H63.2V109.25H58.2Z"
                    fill="#3D7AAF"
                  />
                  <path
                    d="M68.9999 83L67.7999 84.48L65.4699 87.37H67.4699L68.3599 86.63L70.9999 84.48L72.6799 83H68.9999ZM64.6499 88.41V112.34H68.6499V88.44L64.6499 88.41Z"
                    fill="#3D7AAF"
                  />
                  <path
                    d="M79 88.44H73L70 112.37H73.61L74.47 105.89H77.28L78.03 112.37H82.03L79 88.44ZM74.84 102.76L75.84 92.76H76.1L76.93 102.76H74.84Z"
                    fill="#3D7AAF"
                  />
                  <path
                    d="M50.7201 16V25.4C50.4525 25.3552 50.1814 25.3351 49.9101 25.34C48.1418 25.3426 46.4462 26.0442 45.193 27.2917C43.9398 28.5393 43.2306 30.2317 43.2201 32H33.8401C33.8586 27.7501 35.5599 23.6806 38.5716 20.682C41.5833 17.6834 45.6602 16 49.9101 16C50.1801 16 50.4501 16 50.7201 16Z"
                    fill="#EE7203"
                  />
                  <path
                    d="M50.7199 0V9.39H49.9099C43.9112 9.39789 38.1596 11.781 33.9132 16.018C29.6668 20.2551 27.271 26.0013 27.2499 32H17.8799C17.8799 23.5131 21.2513 15.3737 27.2525 9.37258C33.2536 3.37142 41.393 0 49.8799 0L50.7199 0Z"
                    fill="#EE7203"
                  />
                  <path
                    d="M114.16 71C113.957 74.0713 112.594 76.9505 110.347 79.0543C108.101 81.1581 105.138 82.3291 102.06 82.33H96.2501V114.72H84.9201V82.3H72.8201V71H114.16Z"
                    fill="#EE7203"
                  />
                  <path
                    d="M142.69 71.82L127.47 114.69H116.13L105.6 87C109.309 86.1124 112.554 83.8731 114.7 80.72L121.81 98.72L131.36 71.79L142.69 71.82Z"
                    fill="#EE7203"
                  />
                </svg>
              </router-link>
            </div>
          </div>

          <div class="text-center mt-5">
            <router-link
              to="/videos/playlists/"
              class="btn btn-md btn-fiscalia"
              :style="{
                '--background-color-btn': config.disenio.datos.color_primario,
              }"
            >
              <i class="icon"
                ><svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.2969 3.5625H12.7031C12.8281 3.5625 12.8906 3.625 12.8906 3.75V20.25C12.8906 20.375 12.8281 20.4375 12.7031 20.4375H11.2969C11.1719 20.4375 11.1094 20.375 11.1094 20.25V3.75C11.1094 3.625 11.1719 3.5625 11.2969 3.5625Z"
                  />
                  <path
                    d="M4.125 11.1094H19.875C20 11.1094 20.0625 11.1719 20.0625 11.2969V12.7031C20.0625 12.8281 20 12.8906 19.875 12.8906H4.125C4 12.8906 3.9375 12.8281 3.9375 12.7031V11.2969C3.9375 11.1719 4 11.1094 4.125 11.1094Z"
                  /></svg
              ></i>
              Ver Videos
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axiosIns from "@/libs/axios";

export default {
  name: "Multimedia",
  components: {},
  data() {
    return {
      item: [],
    };
  },
  created() {
    this.getPodcast();
  },
  mounted() {},
  computed: {
    config() {
      return this.$store.state.config;
    },
  },
  methods: {
    getPodcast() {
      var url = "/web/podcast";
      axiosIns
        .get(url)
        .then((res) => {
          this.item = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // handler () {
    //   console.log('El componente se visualizó');
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.w-40 {
  width: 40% !important ;
}
</style>
