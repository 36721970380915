<template>
  <section v-if="envivo" class="videos bg-gray">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center pb-4 mt-4">
          <h1
            class="titulo-seccion"
            :style="{
              '--border-color': config.disenio.datos.color_primario,
              '--color-secondary': config.disenio.datos.color_secundario,
            }"
            id="envivo"
            name="envivo"
          >
            Transmisión en Vivo
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center mt-3">
          <div class="row">
            <div id="box-transmision" class="col-md-12">
              <img v-if="item.logo" v-lazy="item.logo" :alt="item.titulo" />

              <div
                class="embed-responsive embed-responsive-16by9 ratio ratio-16x9"
              >
                <iframe
                  class="embed-responsive-item"
                  :src="item.url"
                  allowfullscreen
                ></iframe>
              </div>

              <div class="text-center mt-3">
                <router-link
                  to="/transmisiones"
                  class="btn btn-md btn-fiscalia"
                  :style="{
                    '--background-color-btn':
                      config.disenio.datos.color_primario,
                  }"
                >
                  <i class="icon"
                    ><svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.2969 3.5625H12.7031C12.8281 3.5625 12.8906 3.625 12.8906 3.75V20.25C12.8906 20.375 12.8281 20.4375 12.7031 20.4375H11.2969C11.1719 20.4375 11.1094 20.375 11.1094 20.25V3.75C11.1094 3.625 11.1719 3.5625 11.2969 3.5625Z"
                      />
                      <path
                        d="M4.125 11.1094H19.875C20 11.1094 20.0625 11.1719 20.0625 11.2969V12.7031C20.0625 12.8281 20 12.8906 19.875 12.8906H4.125C4 12.8906 3.9375 12.8281 3.9375 12.7031V11.2969C3.9375 11.1719 4 11.1094 4.125 11.1094Z"
                      /></svg
                  ></i>
                  Transmisiones
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axiosIns from "@/libs/axios";

export default {
  name: "TransmisionEnVivo",
  components: {},
  data() {
    return {
      item: [],
    };
  },
  created() {
    this.getTransmision();
  },
  mounted() {
    //this.$store.commit('setEnvivo', 0);
  },
  computed: {
    envivo() {
      return this.$store.state.envivo;
    },
    config() {
      return this.$store.state.config;
    },
  },
  methods: {
    getTransmision() {
      var url = "/web/transmision-vivo";
      axiosIns
        .get(url)
        .then((res) => {
          this.item = res.data;
          if (this.item) this.$store.commit("setEnvivo", 1);
          else this.$store.commit("setEnvivo", 0);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // handler () {
    //   console.log('this component is showing')
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
