var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.items)?_c('div',{attrs:{"id":"slider-jl"}},[(_vm.items.slideritems.length > 0)?_c('div',[_c('carousel',{attrs:{"autoplay":true,"nav":false,"responsive":{
        0: { items: 1, nav: false },
        600: { items: 1, nav: false },
        900: { items: 1, nav: false },
      }}},_vm._l((_vm.items.slideritems),function(item){return _c('section',{key:item.id,staticClass:"sistema bg-gray pd-0",style:({
          background:
            'url(' + item.urlimagen + ')no-repeat center center / cover',
          '--color-1': _vm.config.disenio.datos.color_secundario + 'f2',
          '--color-2': _vm.config.disenio.datos.color_secundario + 'bf',
          '--color-3': _vm.config.disenio.datos.color_secundario + '59',
        })},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center justify-content-between"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"download-img d-flex align-items-end"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.imagen_miniatura),expression:"item.imagen_miniatura"}],staticClass:"img-fluid",attrs:{"alt":"download"}})])]),_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"text-white pb-100 text-center mt-3"},[(item.isurl_text_1)?_c('div',[(_vm.isExterno(item.url_slider))?_c('a',{attrs:{"target":"_blank","href":item.url_slider}},[_c('h1',{staticClass:"titulo-seccion text-white text-center",style:({
                        '--border-color': _vm.config.disenio.datos.color_primario,
                        '--color-secondary':
                          _vm.config.disenio.datos.color_secundario,
                      })},[_vm._v(" "+_vm._s(item.texto1)+" ")])]):_c('router-link',{attrs:{"to":item.url_slider}},[_c('h1',{staticClass:"titulo-seccion text-white text-center",style:({
                        '--border-color': _vm.config.disenio.datos.color_primario,
                      })},[_vm._v(" "+_vm._s(item.texto1)+"s ")])])],1):_c('div',[_c('h1',{staticClass:"titulo-seccion text-white text-center",style:({
                      '--border-color': _vm.config.disenio.datos.color_primario,
                    })},[_vm._v(" "+_vm._s(item.texto1)+" ")])]),(item.isurl_text_2)?_c('div',[(_vm.isExterno(item.url_slider))?_c('a',{attrs:{"target":"_blank","href":item.url_slider}},[_c('p',{},[_vm._v(_vm._s(item.texto2))])]):_c('router-link',{attrs:{"to":item.url_slider}},[_c('p',{},[_vm._v(_vm._s(item.texto2))])])],1):_c('div',[_c('p',{},[_vm._v(_vm._s(item.texto2))])]),(item.isurl_imagen)?_c('div',{staticClass:"text-center"},[(_vm.isExterno(item.url_slider))?_c('a',{staticClass:"btn btn-md btn-fiscalia",style:({
                      '--background-color-btn':
                        _vm.config.disenio.datos.color_primario,
                    }),attrs:{"href":item.url_slider,"target":"_blank"}},[_vm._v("Ver Detalles")]):_c('router-link',{staticClass:"btn btn-md btn-fiscalia",style:({
                      '--background-color-btn':
                        _vm.config.disenio.datos.color_primario,
                    }),attrs:{"to":item.url_slider}},[_vm._v("Ver Detalles")])],1):_vm._e()])])])])])}),0)],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }