var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.items)?_c('section',{staticClass:"accesos"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row",attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"col-md-12 text-center pb-4"},[_c('h1',{staticClass:"titulo-seccion",style:({
            '--border-color': _vm.config.disenio.datos.color_primario,
            '--color-secondary': _vm.config.disenio.datos.color_secundario,
          })},[_vm._v(" Enlaces de Interés ")])])]),(_vm.items.menuitems.length > 0)?_c('div',{staticClass:"row",attrs:{"data-aos":"fade-up"}},[_c('carousel',{attrs:{"autoplay":true,"nav":false,"responsive":{
          0: { items: 2, nav: false },
          600: { items: 3, nav: false },
          900: { items: 4, nav: false },
          1200: { items: 5, nav: false },
        }}},_vm._l((_vm.items.menuitems),function(item){return _c('div',{key:item.id,staticClass:"box-enlace"},[_c('a',{attrs:{"href":item.menuitemable.url,"target":"_blank"}},[_c('div',{staticClass:"box-img text-center"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.menuitemable.imagen),expression:"item.menuitemable.imagen"}],staticClass:"img-fluid",attrs:{"alt":item.titulo,"title":item.titulo}})]),_c('div',{staticClass:"box-text text-center mt-3"},[_vm._v(" "+_vm._s(item.titulo)+" ")])])])}),0)],1):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }