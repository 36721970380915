var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.items)?_c('section',{staticClass:"accesos mt-5 mb-5"},[_c('div',{staticClass:"container"},[(_vm.items.menuitems.length > 0)?_c('div',{staticClass:"row",attrs:{"data-aos":"fade-up"}},[_c('carousel',{attrs:{"autoplay":true,"nav":false,"responsive":{
          0: { items: 1, nav: false },
          600: { items: 2, nav: false },
          900: { items: 3, nav: false },
          1400: { items: 5, nav: false },
        }}},_vm._l((_vm.items.menuitems),function(item){return _c('div',{key:item.id},[(item.menuitemable.tipoenlace == 1)?_c('router-link',{attrs:{"to":item.menuitemable.url}},[_c('div',{staticClass:"box-acceso",style:({
                '--background-color-tertiary':
                  _vm.config.disenio.datos.color_terciario,
              })},[_c('div',{staticClass:"box-icon text-center"},[(item.menuitemable.tipoenlace == 1)?_c('router-link',{staticClass:"btn-icon-round",style:({
                    '--background-color-icon':
                      _vm.config.disenio.datos.color_primario,
                  }),attrs:{"to":item.menuitemable.url}},[_c('i',{staticClass:"icon-white",domProps:{"innerHTML":_vm._s(item.menuitemable.icono)}})]):_c('a',{staticClass:"btn-icon-round",attrs:{"href":item.menuitemable.url,"target":"_blank"}},[_c('i',{staticClass:"icon-white",domProps:{"innerHTML":_vm._s(item.menuitemable.icono)}})])],1),_c('div',{staticClass:"box-text"},[_c('h1',{staticClass:"text-center color-primary",style:({
                    '--color-secondary':
                      _vm.config.disenio.datos.color_secundario,
                  })},[_vm._v(" "+_vm._s(item.nombre)+" ")])])])]):_c('a',{attrs:{"href":item.menuitemable.url,"target":"_blank"}},[_c('div',{staticClass:"box-acceso"},[_c('div',{staticClass:"box-icon text-center"},[(item.menuitemable.tipoenlace == 1)?_c('router-link',{staticClass:"btn-icon-round",attrs:{"to":item.menuitemable.url}},[_c('i',{staticClass:"icon-white",domProps:{"innerHTML":_vm._s(item.menuitemable.icono)}})]):_c('a',{staticClass:"btn-icon-round",attrs:{"href":item.menuitemable.url,"target":"_blank"}},[_c('i',{staticClass:"icon-white",domProps:{"innerHTML":_vm._s(item.menuitemable.icono)}})])],1),_c('div',{staticClass:"box-text"},[_c('h1',{staticClass:"text-center"},[_vm._v(_vm._s(item.titulo))]),_c('p',{staticClass:"text-center"})])])])],1)}),0)],1):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }