<template>
  <section v-if="items" class="accesos mt-5 mb-5">
    <div class="container">
      <!-- <div class="row">
          <div class="col-md-12">
            <h1 class="titulo-seccion">Accesos</h1>
          </div>
        </div> -->
      <div class="row" data-aos="fade-up" v-if="items.menuitems.length > 0">
        <carousel
          :autoplay="true"
          :nav="false"
          :responsive="{
            0: { items: 1, nav: false },
            600: { items: 2, nav: false },
            900: { items: 3, nav: false },
            1400: { items: 5, nav: false },
          }"
        >
          <div v-for="item in items.menuitems" :key="item.id" class="">
            <router-link
              v-if="item.menuitemable.tipoenlace == 1"
              :to="item.menuitemable.url"
              class=""
            >
              <div
                class="box-acceso"
                :style="{
                  '--background-color-tertiary':
                    config.disenio.datos.color_terciario,
                }"
              >
                <div class="box-icon text-center">
                  <router-link
                    v-if="item.menuitemable.tipoenlace == 1"
                    :to="item.menuitemable.url"
                    class="btn-icon-round"
                    :style="{
                      '--background-color-icon':
                        config.disenio.datos.color_primario,
                    }"
                  >
                    <i class="icon-white" v-html="item.menuitemable.icono"></i>
                  </router-link>
                  <a
                    v-else
                    :href="item.menuitemable.url"
                    target="_blank"
                    class="btn-icon-round"
                  >
                    <i class="icon-white" v-html="item.menuitemable.icono"></i>
                  </a>
                </div>
                <div class="box-text">
                  <h1
                    class="text-center color-primary"
                    :style="{
                      '--color-secondary':
                        config.disenio.datos.color_secundario,
                    }"
                  >
                    {{ item.nombre }}
                  </h1>
                </div>
              </div>
            </router-link>
            <a v-else :href="item.menuitemable.url" target="_blank" class="">
              <div class="box-acceso">
                <div class="box-icon text-center">
                  <router-link
                    v-if="item.menuitemable.tipoenlace == 1"
                    :to="item.menuitemable.url"
                    class="btn-icon-round"
                  >
                    <i class="icon-white" v-html="item.menuitemable.icono"></i>
                  </router-link>
                  <a
                    v-else
                    :href="item.menuitemable.url"
                    target="_blank"
                    class="btn-icon-round"
                  >
                    <i class="icon-white" v-html="item.menuitemable.icono"></i>
                  </a>
                </div>
                <div class="box-text">
                  <h1 class="text-center">{{ item.titulo }}</h1>
                  <p class="text-center"></p>
                </div>
              </div>
            </a>
          </div>
        </carousel>
      </div>
    </div>
  </section>
</template>

<script>
import axiosIns from "@/libs/axios";
import carousel from "vue-owl-carousel";

export default {
  name: "AccesosPrincipal",
  components: {
    carousel,
  },
  data() {
    return {
      items: {
        menuitems: [],
      },
    };
  },
  mounted() {
    this.listar();
  },
  methods: {
    listar() {
      var url = "/web/enlaces/principal";
      axiosIns
        .get(url)
        .then((res) => {
          this.items = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    config() {
      return this.$store.state.config;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.box-text * {
  text-transform: uppercase;
  font-size: 1.2rem !important;
}
</style>
